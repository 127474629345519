.cv_img{
    width: 10vw;
    border-radius: 50%;
}

.Flex{
    display: flex;
    justify-content: space-between;
    margin: 5%;
    gap: 5em;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}

.Flex1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5em;
    flex-wrap: wrap;
    padding-top: 5em;
}

.Flex_Item1{
    flex: 1;
}

.font{
    font-size: 0.9vw;
}
.Flex_Item2{
    flex: 2;
    max-width: 28.75em;

}

.Flex_Item3{
    flex: 1.8;
    margin-left: 20%;
    text-align: right;
}

.Flex_Item3 .font {
    display: block; /* Display each span on a new line */
  }

.flex_item {
    flex: 2;
}

.flex_item2{
    flex: 2.7;
}

.Cv_Container{
    background-color: rgb(43, 37, 48);
    line-height: 1.2rem;
}

 .Cv_Container li,  .Cv_Container span{
    font-size: 1em;
}



@media screen and (max-width:768px) {
    .Cv_Container p{
        font-size: 1.5vw;
    }

    .Cv_Container h3{
        font-size: 2vw;
    }

    .Cv_Container h4{
        font-size: 1.8vw;
    }

    .Cv_Container h5,   .Cv_Container span{
        font-size: 1.5vw;
    }

    .Cv_Container{
        background-color: rgb(20, 18, 34);
        padding: 0%;
        line-height: 0.8rem;
    }

    .Flex1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em;
        flex-wrap: wrap;
        padding-top: 2%;
    }
    
    .Flex_Item3{
        flex: 1.8;
        margin-left: 5%;
    }

    .Cv_Container li{
        font-size: 0.5em;
    }
}

@media screen and (max-width:500px) {
    .Cv_Container p{
        font-size: 1.5vw;
    }

    .Cv_Container h3{
        font-size: 2vw;
    }

    .Cv_Container h4{
        font-size: 1.8vw;
    }

    .Cv_Container h5,   .Cv_Container span{
        font-size: 1.5vw;
    }

    .Cv_Container{
        background-color: rgb(20, 18, 34);
        padding: 0%;
        line-height: 0.5rem;
    }

    .Flex1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em;
        flex-wrap: wrap;
        padding-top: 2%;
    }
    
    .Flex_Item3{
        flex: 1.8;
        margin-left: 5%;
    }

    .Cv_Container li{
        font-size: 0.5em;
    }

    .Flex{
        display: flex;
        justify-content: space-between;
        margin: 4%;
        gap: 2em;
        flex-wrap: wrap;
        padding-bottom: 2rem;
    }
    
    .Flex1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em;
        flex-wrap: wrap;
        padding-top: 2%;
    }
    .flex_item {
        flex: 1;
    }
    
    .flex_item2{
        flex: 1.5;
    }
}

@media screen and (min-width:1440px){
    .Flex_Item3{
        flex: 1.8;
        margin-left: 25%;
    }

    .Flex{
        display: flex;
        margin: 4% ;
        gap: 10em;
        flex-wrap: wrap
    }
}


