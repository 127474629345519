.margin-left{
   margin-left: 42%;
   text-align: end;
}

.Contact_Flex{
 display: flex;
 justify-content: center;
 gap: 5%;
}


.text_vertical{
    writing-mode: vertical-rl; 
    text-orientation: mixed; 
    white-space: nowrap; 
    font-weight: 900;
    font-size: 9rem;
    color: rgb(122, 120, 120);
}

.text{
    font-weight: 900;
    font-size: 3rem;
    color: rgb(109, 109, 109);
    margin-left: 100%;
}

.Contact_details {
    margin-top: 15%;
}

.Top{
    margin-top: 25%;
    margin-bottom: 10%;
}



@media screen and (min-width: 1400px){

    .text{
        font-weight: 900;
        font-size: 3.5rem;
        color: rgb(109, 109, 109);
        margin-left: 75%;
    }
    
}

@media screen and (min-width: 1900px){

    .text{
        font-weight: 900;
        font-size: 3.5rem;
        color: rgb(109, 109, 109);
        margin-left: 75%;
    }

    .margin-left{
        margin-left: 42%;
        text-align: end;
     }
     
 
  
}

@media screen and (min-width: 2500px){

    .text{
        font-weight: 900;
        font-size: 3.5rem;
        color: rgb(109, 109, 109);
        margin-left: 75%;
    }
    
}
@media screen and (width: 1024px) {

    .text{
        font-weight: 800;
        font-size: 3rem;
        color: rgb(109, 109, 109);
          margin-left: 68%;
    }

    .margin-left{
        margin-left: 20%;
        text-align: end;
     }
     
     .Top{
        margin-top: 40%;
        margin-bottom: 10%;
    }
    
}

@media screen and (width: 768px) {

    .text{
        font-weight: 900;
        font-size: 3rem;
        color: rgb(109, 109, 109);
        margin-left: 60%;
    }
    
    .margin-left{
        margin-left: -7%;
        text-align: end;
     }

     .Top{
        margin-top: 55%;
        margin-bottom: 10%;
    }
     
}




@media screen and (max-width:500px){
    .text{
        font-weight: 700;
        font-size: 1.8rem;
        color: rgb(109, 109, 109);
        margin-left: 72%;
    }
    
    .margin-left{
        margin-left: 39%;
        text-align: end;
     }
     

    .text_vertical{
        writing-mode: vertical-rl; 
        text-orientation:  mixed; 
        white-space: nowrap; 
        font-weight: 900;
        font-size: 4rem;
        color: rgb(122, 120, 120);
    }
    
    .contact_Container{
        display: flex;
        justify-content: center;
        margin-top: 5% ;
    }
    
    .Contact_details {
        margin-top: 10%;
    }

    .Contact_details h4 , .Contact_details h3{
        font-size: 3vw;
    }
    .Contact_details p , .Contact_details span {
        font-size: 2vw;
    }
}
