.margin{
 margin: 5% 3.5%
}

.main{
    width:100%
}

.Portfolio_Container{
 margin-top: 5%;
}

.CaseStudy {
    position: relative;
    overflow: hidden;
  }
  
  .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 96.5%;
    margin-left: 2%;
    height: 100%;
    background-color: rgba(109, 109, 109 , 0.5);
    font-size: 2vw;
    color: #fff; 
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .CaseStudy:hover .Overlay {
    opacity: 3;
  }
  
 
.full-viewport{
  width: 100%;
}

@media screen and (min-width: 1440px){
  .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 97.5%;
    margin-left: 1.3%;
    height: 100%;
    background-color: rgba(109, 109, 109 , 0.5);
    font-size: 2vw;
    color: #fff; 
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}





